export const TOKEN_TYPE = "Bearer ";
export const REQUEST_HEADER_AUTH_KEY = "Authorization";
export const SML_AUTH_HEADER_KEY = "Basic c21saWNybTpTbWxpQENSTTI0";
export const BUCKET_URL = "https://vahan-pro.s3.ap-south-1.amazonaws.com/";

export const USERS = { ADMIN: "admin", VENDOR: "vendor" };
export const APIS = {
  AUTH: {
    LOGIN: "/admin/login",
    VENDOR_LOGIN: "/vendor/login",
  },

  VENDORS: {
    ADDEDIT_VENDOR: "/admin/addEditVendor",
  },

  VENDORS_LINK: {
    USERS: "/vendor/listUsers",
    ADDEDIT_USERS: "/vendor/registerUser",
  },

  ADMIN_BLOCKDELETEDATA: "/admin/blockDeleteData",

  ADMIN_LINK: {
    LISTDATA: "/admin/listData",
    ADDEDIT_ADMINS: "/admin/addEditAdmins",
  },

  DESTINATION_AND_QUOTA: {
    LISTING: "/vendor/listDesignations",
    ADDEDIT_DESTINATION_AND_QUOTA: "/vendor/addEditDesignation",
  },
  BULK_UPLOAD : "/vendor/bulkUpload",
  BULK_UPLOAD_LOGS : "/vendor/bulkLogs",
  EXPORT_DATA : "/vendor/exportData",
  ADMIN: {
    CREATE_ADMIN: "/admin/create",
    DELETE_ADMIN: "/admin/delete",
    EDIT_ADMIN: "/admin/editAdmin",
  },
 
  ADMIN_LIST: "/admin/getAdmin",
  LIST_VENDORS: "/admin/listData",

  
  VENDOR_DASHBOARD: "/vendor/dashboard",

  CHANGE_PASSWORD: "/crmapi/api/UserLogin/UserChangePassword",
};
