import { APIS } from "constants/api.constant";
import { postApi, getApi } from "./CommonService";
import appConfig from "configs/app.config";
import { toast, Notification } from "components/ui";
import { getState } from "store";

export const getAdminListing = async ({ page, limit, search, status }) => {
  return await getApi(APIS.ADMIN_LINK.LISTDATA, {
    skip: page,
    limit: limit,
    search: search,
    type: 1,
    status: status,
  }).then((res) => {
    return res;
  });
};

export const getVendorsListing = async ({
  page,
  limit,
  search,
  type,
  status,
}) => {
  return await getApi(APIS.LIST_VENDORS, {
    skip: page,
    limit: limit,
    search: search,
    type: type,
    status: status.value,
  }).then((res) => {
    return res;
  });
};

export const getUsersListing = async ({ page, limit, search }) => {
  return await getApi(APIS.VENDORS_LINK.USERS, {
    skip: page,
    limit: limit,
    search: search,
  }).then((res) => {
    return res;
  });
};
export const getDestinationListing = async ({ page, limit, search }) => {
  return await getApi(APIS.DESTINATION_AND_QUOTA.LISTING, {
    skip: page,
    limit: limit,
    search: search,
  }).then((res) => {
    return res;
  });
};

// export const getUserListing = async ({
//   pageIndex,
//   pageSize,
//   totalPages,
//   filterText,
//   userType,
//   otherObj,
// }) => {
//   return await postApi(APIS.LIST_USERS, {
//     pageIndex,
//     pageSize,
//     totalPages,
//     filterText,
//     userType: [userType],
//     ...otherObj,
//   }).then((res) => {
//     return res;
//   });
// };

export const addUpdateuser = async (data) => {
  return await postApi(APIS.UPDATE_USER, { ...data }).then((res) => {
    return res;
  });
};

export const getUserById = async ({ id: id }) => {
  return await getApi(APIS.USER_BY_ID, { id: Number(id) }).then((res) => res);
};

export const getCommonAPIListing = async ({
  api,
  pageIndex,
  pageSize,
  totalPages,
  filterText,
  otherObj,
}) => {
  return await postApi(api, {
    pageIndex,
    pageSize,
    totalPages,
    filterText,
    ...otherObj,
  }).then((res) => {
    return res;
  });
};

export const excelExportCommon = async ({
  bodyData,
  setExportLoading
}) => {
  try {
    setExportLoading(true);
    const postResponse = await fetch(
      `${appConfig.apiBaseUrl}${APIS.EXPORT_DATA}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getState().auth?.session?.token}`,
        },
        body: JSON.stringify(bodyData),
      }
    );

    if (!postResponse?.ok) {
     
      const errorData = await postResponse.json();
      toast.push(
        <Notification type="danger">
          {errorData?.message || `HTTP error in POST API! Status: ${postResponse?.status}`}
        </Notification>
      );
      setExportLoading(false);
      return;
    }

    const postData = await postResponse.json();

    if (postData?.statusCode !== 200) {
      toast.push(
        <Notification type="danger">
          {postData?.message || "An error occurred while processing your request."}
        </Notification>
      );
      setExportLoading(false);
      return;
    }

    const filePath = postData?.data?.path;
    if (!filePath) {
      toast.push(
        <Notification type="danger">
          Failed to get the file path from POST API response.
        </Notification>
      );
      return;
    }

    const getResponse = await fetch(`${appConfig.apiBaseUrl}/${filePath}`, {
      method: "GET",
    });

    if (!getResponse.ok) {
      toast.push(
        <Notification type="danger">
          {`Unable to download the Excel file: ${getResponse.statusText}`}
        </Notification>
      );
      return;
    }

    const blob = await getResponse.blob();

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = postData?.data?.name || "downloaded_excel_file.xlsx";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

    toast.push(
      <Notification type="success">
        Excel file downloaded successfully!
      </Notification>
    );
  } catch (error) {
    console.error(error);
    toast.push(
      <Notification type="danger">
        Failed to download the Excel file: {error.message}
      </Notification>
    );
  } finally {
    setExportLoading(false);
  }
};
